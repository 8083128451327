import * as Sentry from '@sentry/browser';

/**
 * @see https://sentry.io/
 */
export const sentry = (host: string, dsn: string) => {
    const currentHost = host.replace('www.', '');

    if (
        currentHost.includes('localhost') ||
        // We use vhost ending with ".test" during local development.
        currentHost.includes('.test') ||
        // Don't run on non-production domains.
        currentHost.startsWith('dev.') ||
        currentHost.startsWith('preview.') ||
        currentHost.endsWith('curaytor.site')
    ) {
        return;
    }

    Sentry.init({ dsn });
};
