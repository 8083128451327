/* eslint-disable @typescript-eslint/ban-ts-ignore */
import 'react-hot-loader/root';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ResizeObserver from 'resize-observer-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import { mountExternalScripts } from './services/externalScripts/mountExternalScripts';
import { setupErrorTracking } from './services/setupErrorTracking';

import './styles/index.scss';

(window as any).ResizeObserver =
  (window as any).ResizeObserver || ResizeObserver;

setupErrorTracking();

/**
 * Add an icon to app title to discern different instances.
 * This will be stripped from the build.
 */
if (
  process.env.NODE_ENV === 'development' &&
  window.location.hostname === 'localhost'
) {
  document.title += ' 🌴';

  const DEBUG_PERF = false;
  if (DEBUG_PERF) {
    // @ts-ignore // for CI
    type WhyDidYouRender = typeof import('@welldone-software/why-did-you-render').default;
    // eslint-disable-next-line global-require, import/no-extraneous-dependencies
    const whyDidYouRender = require('@welldone-software/why-did-you-render') as WhyDidYouRender;

    whyDidYouRender(React, {
      trackAllPureComponents: true,
      collapseGroups: true,
      trackExtraHooks: [
        [
          // eslint-disable-next-line global-require
          require('./data/store')
            .__internal_import_me_only_for_debugging__store,
          'useSelectors',
        ],
      ],
    });
  }
} else {
  mountExternalScripts();
}

Promise.all([
  // @ts-ignore
  import('core-js/modules/web.url'),
  // @ts-ignore
  import('core-js/modules/web.url-search-params'),
  // @ts-ignore
  import('core-js/modules/es.promise.all-settled'),
])
  .then(() => import('./components/App'))
  .then(({ default: App }) => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
