import { captureException } from '@sentry/browser';
import { sentry } from 'curaytor-lib/src/services/sentry';

export function setupErrorTracking() {
  function consoleArgsToError(args: any[]) {
    return new Error(
      args.reduce((acc, val) => `${String(acc)} ${String(val)}`, '')
    );
  }

  const oldConsoleError = console.error.bind(console);
  function consoleErrorAndReport(...args: [unknown?, ...unknown[]]) {
    captureException(consoleArgsToError(args));
    return oldConsoleError(...args);
  }

  console.error = consoleErrorAndReport;

  sentry(
    window.location.host,
    'https://6cce251874884cdab7eaa0477fca06e7@sentry.io/1870814'
  );
}
